import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationEn from './locales/en/translation.json';
import translationTr from './locales/tr/translation.json';
import translationRu from './locales/ru/translation.json';
import translationFr from './locales/fr/translation.json';


const resources = {
  En: {
    translation: translationEn,
  },
  Tr: {
    translation: translationTr,
  },
  Ru: {
    translation: translationRu,
  },
  Fr: {
    translation: translationFr,
  },
};

i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: 'En',
      interpolation: {
        escapeValue: false,
        keySeparator: "."
      },
    });

export default i18n;