import React from 'react';
import s from './Advantages.module.css'
import img from '../../../assets/img/advantages.png'
import line from '../../../assets/img/line_mobile.png'
import {useTranslation} from "react-i18next";

const Advantages = () => {
  const {t} = useTranslation();

  const locale = localStorage.getItem('lang');

  return (
      <div id={"3"} className={s.container}>
        <div className={s.imgBlock}></div>
        <div className={s.descBlock}>
          <div className={s.title}>{t('advantages.title')}</div>
          <div className={s.cardBlock}>
            <div className={s.lineBlock}>
              <div className={s.line}>
                <img src={line} alt=""/>
              </div>
              <div className={s.text}>{t('advantages.advantagesTextOne')}</div>
            </div>
            <div className={s.lineBlock}>
              <div className={s.line}>
                <img src={line} alt=""/>
              </div>
              <div className={s.text}>{t('advantages.advantagesTextTwo')}</div>
            </div>
            <div className={s.lineBlock}>
              <div className={s.line}>
                <img src={line} alt=""/>
              </div>
              <div className={s.text}>{t('advantages.advantagesTextThree')}</div>
            </div>
            <div className={s.lineBlock}>
              <div className={s.line}>
                <img src={line} alt=""/>
              </div>
              <div className={s.text}>{t('advantages.advantagesTextFour')}</div>
            </div>
            <div className={s.lineBlock}>
              <div className={s.line}>
                <img src={line} alt=""/>
              </div>
              <div className={s.text}>{t('advantages.advantagesTextFive')}</div>
            </div>
            <div className={s.lineBlock}>
              <div className={s.line}>
                <img src={line} alt=""/>
              </div>
              <div className={s.text}>{t('advantages.advantagesTextSix')}</div>
            </div>
            <div className={s.lineBlock}>
              <div className={s.line}>
                <img src={line} alt=""/>
              </div>
              <div className={s.text}>{t('advantages.advantagesTextSeven')}</div>
            </div>
          </div>
        </div>
        <div className={s.imgBlockMobile}></div>
      </div>
  );
};

export default Advantages;
